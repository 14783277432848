const Service = {
    _utterance: undefined,
    speak(message) {
        if (!this.isAvailable()) {
            return;
        }
        this.stop();
        let utterance = this.utterance();
        utterance.text = message;
        window.speechSynthesis.speak(utterance);
    },
    stop() {
        window.speechSynthesis.cancel();
    },
    utterance() {
        if (!this._utterance) {
            this._utterance = new SpeechSynthesisUtterance();
            this._utterance.rate = 1.5;
            this._utterance.lang = 'en-US';
        }

        return this._utterance;
    },
    isAvailable() {
        if ('speechSynthesis' in window) {
            return true;
        } else {
            return false;
        }
    }
}

export default Service
