<template>
  <img v-bind:alt="alt" v-bind:src="`/cards/${imagePath}`"/>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    selected: {
      default() {
        return false;
      }
    },
    rank: {
      required: true
    },
    suit: {
      required: true
    },
    name: {
      required: true
    },
  },
  data: () => ({}),
  computed: {
    alt() {
      let parts = [];
      if (this.selected) {
          parts.push('selected');
      }
      parts.push(this.name)
      return parts.join(' ');
    },
    imagePath() {
      return `${this.rank}_of_${this.suit}.svg`;
    }
  }
}
</script>
