import { render, staticRenderFns } from "./opponent-perspective.vue?vue&type=template&id=4f899b54&scoped=true&"
import script from "./opponent-perspective.vue?vue&type=script&lang=js&"
export * from "./opponent-perspective.vue?vue&type=script&lang=js&"
import style0 from "./opponent-perspective.vue?vue&type=style&index=0&id=4f899b54&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f899b54",
  null
  
)

export default component.exports