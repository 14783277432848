<template>
  <div>
    <v-btn block></v-btn>
  </div>
</template>

<script>
import CardImage from "@/components/card-image";

export default {
  name: 'dealer-controls',
  props: {},
  data: () => ({}),
  methods: {},
  computed: {},
  components: {
    CardImage
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>