<template>
  <div style="height: 100%;">
    <template v-if="!session">
      Retrieving session
    </template>
    <template v-else-if="playerIndex === undefined">
      <v-btn v-on:click="joinGame" block>Join this game</v-btn>
    </template>
    <template v-else-if="visuallyImpaired">
      <div class="d-flex flex-column" style="height: 100%;">
        <div class="d-flex py-2">
          <div class="flex-grow-1" style="width:33.3%">
            <v-btn height="100px" block v-on:click="sayFocusedPlayerDetails">{{ focusedPlayer.name }}</v-btn>
          </div>
          <div class="flex-grow-1" style="width:33.3%">
            <v-btn height="100px" block v-bind:disabled="focusPlayerIndex === 0"
                   v-on:click="focusOnPlayer(focusPlayerIndex - 1)">Left
            </v-btn>
          </div>
          <div class="flex-grow-1" style="width:33.3%">
            <v-btn height="100px" block v-bind:disabled="focusPlayerIndex === players.length - 1"
                   v-on:click="focusOnPlayer(focusPlayerIndex + 1)">Right
            </v-btn>
          </div>
        </div>

        <div class="flex-grow-1"></div>

        <player-controls v-bind:player="players[playerIndex]"
                         v-bind:visually-impaired="visuallyImpaired"
                         v-on:show-cards="(holdingIndexes) => showCardsForPlayer(playerIndex, holdingIndexes)"
                         v-on:unshow-cards="(showingIndexes) => unshowCardsForPlayer(playerIndex, showingIndexes)"
                         v-on:discard-cards="(discardIndexes) => discardCardsForPlayer(playerIndex, discardIndexes)"
                         v-on:undiscard-cards="(discardIndexes) => undiscardCardsForPlayer(playerIndex, discardIndexes)"
        />
        <div v-if="false">
          <v-btn v-on:click="readLog" block>Read log</v-btn>
        </div>
        <div v-if="false" style="flex: 1 1 auto; overflow-y: auto; background: green; height: 0px;">
          <template v-for="log in logs">
            <small>{{ log }}</small><br/>
          </template>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="d-flex flex-column" style="height: 100%;">
        <div v-if="showLogs" style="max-height: 100px; overflow-y: auto;" class="pa-1">
          <template v-for="log in logs">
            <small>{{ log }}</small><br/>
          </template>
        </div>
        <div style="flex: 1 1 auto; overflow-y: auto; background: green; height: 0px;">
          <div class="d-md-flex">
            <v-card color="#eee">
              <v-card-text class="pa-1">
                <div class="d-flex">
                  <div class="mr-2">
                    <v-btn x-small depressed v-on:click="showLogs = !showLogs">Logs</v-btn>
                  </div>
                  <div class="flex-grow-1">
                    <strong>Dealer</strong>
                  </div>
                  <div class="d-flex">
                    <div class="flex-grow-1 d-flex justify-end" v-if="deck.length > 0">
                      <div class="d-flex holding">
                        <card-image style="width: 20px;" class="card-image holding pa-1" suit="holding" name="back"
                                    rank="back"/>
                      </div>
                      <div class="d-flex justify-center align-center">
                        <div class="holding-count">{{ deck.length }}</div>
                      </div>
                    </div>

                    <div class="flex-grow-1 d-flex justify-end" v-if="collectedCards.length > 0">
                      <div class="d-flex holding">
                        <card-image style="width: 20px;" class="card-image holding pa-1" suit="discarded" name="back"
                                    rank="back"/>
                      </div>
                      <div class="d-flex justify-center align-center">
                        <div class="holding-count">{{ collectedCards.length }}</div>
                      </div>
                    </div>

                  </div>
                </div>
              </v-card-text>
            </v-card>

            <template v-for="player in players">
              <opponent-perspective class="ma-1" v-bind:player="player" v-bind:labels="labelsFor(player)"/>
            </template>
          </div>
        </div>
        <div style="box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.5); z-index:1;">
          <template v-if="playerId === dealerPlayerId">
            <div class="d-flex">
              <v-btn class="flex-grow-1" v-on:click="showPlayerControlView" depressed>Player</v-btn>
              <v-btn class="flex-grow-1" v-on:click="showDealerControlView" depressed>Dealer</v-btn>
            </div>
          </template>
          <template v-if="computedControlView === 'PLAYER'">
            <player-controls v-bind:player="players[playerIndex]"
                             v-on:show-cards="(holdingIndexes) => showCardsForPlayer(playerIndex, holdingIndexes)"
                             v-on:unshow-cards="(showingIndexes) => unshowCardsForPlayer(playerIndex, showingIndexes)"
                             v-on:discard-cards="(discardIndexes) => discardCardsForPlayer(playerIndex, discardIndexes)"
                             v-on:undiscard-cards="(discardIndexes) => undiscardCardsForPlayer(playerIndex, discardIndexes)"
            />
          </template>
          <template v-else-if="computedControlView === 'DEALER'">
            <div class="d-md-flex pa-2">
              <div class="d-md-flex">
                <div class="d-flex">
                  <div>
                    <v-select v-model="dealToPlayer"
                              label="Player"
                              outlined
                              clearable
                              hide-details
                              v-bind:items="dealPlayerItems"/>
                  </div>
                  <div style="width: 150px;">
                    <v-text-field label="Deal count"
                                  type="number" v-model="dealCount"
                                  outlined
                                  clearable
                                  hide-details
                    />
                  </div>
                  <div>
                    <v-btn style="height:100%;"
                           depressed
                           v-on:click="dealCardsToPlayer(dealToPlayer, dealCount)">Deal
                    </v-btn>
                  </div>
                </div>
                <div class="py-2">
                  <v-btn block v-on:click="shuffleDeck">Shuffle</v-btn>
                  <v-btn block v-on:click="collectShowing">Collect Showing</v-btn>
                  <v-btn block v-on:click="collectDiscarded">Collect Discarded</v-btn>
                  <v-btn block v-on:click="putDiscardedBackToDeck">Discarded back to Deck</v-btn>
                  <v-text-field v-model="sessionLink" outlined hide-details/>
                </div>
              </div>
              <div class="flex-grow-1"></div>
              <div class="d-md-flex">
                <v-select dense label="Exclude Ranks" outlined hide-details clearable multiple v-bind:items="ranks"
                          v-model="excludeRanks"></v-select>
                <v-select dense label="Exclude Suits" outlined hide-details clearable multiple v-bind:items="suits"
                          v-model="excludeSuits"></v-select>
                <v-select dense label="Exclude Cards" outlined hide-details clearable multiple
                          v-bind:items="allCards.map(card => card.key)"
                          v-model="excludeCards"></v-select>
                <v-btn v-on:click="reset" block>Reset</v-btn>
              </div>
              <div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </template>


    <!--    <div class="d-flex" v-if="false">-->
    <!--      <div class="flex-grow-1">-->
    <!--        <div class="d-flex">-->
    <!--          <template v-for="card in cardsOnTable">-->
    <!--            <div class="flex-grow-1 pa-2">-->
    <!--              <v-card>-->
    <!--                <v-card-text>-->
    <!--                  <pre>{{ card }}</pre>-->
    <!--                </v-card-text>-->
    <!--              </v-card>-->
    <!--            </div>-->
    <!--          </template>-->
    <!--        </div>-->


    <!--        <div class="d-flex">-->
    <!--          <div>-->

    <!--          </div>-->
    <!--          <div>-->

    <!--          </div>-->
    <!--          <div>-->
    <!--            Deck {{ deck.length }}-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <div style="border-left: 1px solid #eee;" class="pa-1 d-flex">-->
    <!--        <div>-->
    <!--          Deck-->
    <!--          <template v-for="card in deck">-->
    <!--            <div>-->
    <!--              {{ card }}-->
    <!--            </div>-->
    <!--          </template>-->
    <!--        </div>-->
    <!--        <div>-->
    <!--          Debug-->
    <!--          <pre>-->
    <!--focusPlayer: {{ focusPlayer }}-->
    <!--excludeSuits: {{ excludeSuits }}-->
    <!--excludeRanks: {{ excludeRanks }}-->
    <!--excludeCard: {{ excludeCards }}-->
    <!--possibleCards: {{ possibleCards }}-->
    <!--        </pre>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>

<script>
const shuffleSeed = require('shuffle-seed');
import CardImage from '../components/card-image'
import OpponentPerspective from "@/components/opponent-perspective";
import PlayerControls from "@/components/player-controls";
import DealerControls from "@/components/dealer-controls";
import FirebaseClient from "@/clients/firebaseClient";
import store from "store";
import TextToSpeech from "@/services/textToSpeech";
import {diff, addedDiff, deletedDiff, updatedDiff, detailedDiff} from 'deep-object-diff';
import _get from 'lodash/get'

const LOG_ACTIONS = {
  'SHOW_CARDS': 'SHOW_CARDS',
  'UNSHOW_CARDS': 'UNSHOW_CARDS',
  'DISCARD_CARDS': 'DISCARD_CARDS',
  'UNDISCARD_CARDS': 'UNDISCARD_CARDS',
  'DEALER_BUILD_DECK': 'DEALER_BUILD_DECK',
  'DEALER_SHUFFLE': 'DEALER_SHUFFLE',
  'DEALER_DEAL_CARDS_TO_PLAYER': 'DEALER_DEAL_CARDS_TO_PLAYER'
}

const STATE_KEYS = [
  'deck',
  'logs',
  'players',
  'excludeSuits',
  'excludeRanks',
  'excludeCards',
  'collectedCards',
  'dealerPlayerId',
]

export default {
  name: 'Home',
  data() {
    return {
      focusPlayerIndex: 0,
      seed: this.routeSessionId,
      session: undefined,
      showLogs: false,
      dealToPlayer: 0,
      dealCount: 0,
      deck: [],
      logs: [],
      players: [],
      cardsOnTable: [],
      playerCount: 4,
      excludeSuits: [],
      excludeRanks: [],
      excludeCards: [],
      collectedCards: [],
      dealerPlayerId: 0,
      currentPlayerId: undefined,
      controlView: 'PLAYER',
    }
  },
  computed: {
    visuallyImpaired() {
      return this.$route.query['visuallyImpaired'] !== undefined;
    },
    sessionLink() {
      return `https://onlinecards.app/?joinSessionId=${this.routeSessionId}`;
    },
    dealPlayerItems() {
      return [
        {
          value: 'all',
          text: 'All'
        },
        ...this.players.map((player, index) => {
          return {
            value: index, text: player.name
          };
        })
      ];
    },
    playerId() {
      return store.get('PLAYER_ID');
    },
    playerIndex() {
      let foundIndex;
      this.players.every((player, index) => {
        if (player.id === this.playerId) {
          foundIndex = index;
          return false;
        }
        return true;
      });
      return foundIndex;
    },
    routeSessionId() {
      return this.$route.params['sessionId'];
    },
    computedControlView() {
      if (this.dealerPlayerId !== this.currentPlayerId) {
        return 'PLAYER';
      }

      return this.controlView;
    },
    playersWithoutCurrentPlayer() {
      return this.players.filter((player) => {
        return player.id !== this.currentPlayerId;
      })
    },
    suits() {
      return "hearts clubs diamonds spades".split(" ");
    },
    ranks() {
      return "ace 2 3 4 5 6 7 8 9 10 jack queen king".split(" ");
    },
    allCards() {
      let _this = this;
      return _this.ranks.flatMap(function (rank) {
        return _this.suits.map(function (suit) {
          return {
            rank,
            suit,
            key: `${suit}_${rank}`,
            name: `${rank} of ${suit}`,
          };
        });
      });
    },
    possibleCards() {
      return this.allCards.filter((card) => {
        if (this.excludeSuits.indexOf(card.suit) > -1) {
          return false;
        }

        if (this.excludeRanks.indexOf(card.rank) > -1) {
          return false;
        }

        if (this.excludeCards.indexOf(card.key) > -1) {
          return false;
        }

        return true;
      });
    },
    focusedPlayer() {
      return this.players[this.focusPlayerIndex];
    },
  },
  methods: {
    readLog() {
      TextToSpeech.speak(this.logs.join(','));
    },
    sayFocusedPlayerDetails() {
      let sentences = [
        `${this.focusedPlayer.name}`
      ]

      let discarded = this.focusedPlayer.discarded.map((card) => {
        return card.name;
      });

      let holding = this.focusedPlayer.holding.map((card) => {
        return card.name;
      });

      let showing = this.focusedPlayer.showing.map((card) => {
        return card.name;
      });

      sentences.push(`Showing ${showing.length > 0 ? showing.join(', ') : 'no cards'}`);
      sentences.push(`Holding ${holding.length} cards`);
      sentences.push(`Discarded ${discarded.length} cards`);

      TextToSpeech.speak(sentences.join(', '));
    },
    focusOnPlayer(index) {
      this.focusPlayerIndex = index;
      TextToSpeech.speak(`${this.focusedPlayer.name}`);
    },

    joinGame() {
      this.players.push({
        "id": this.playerId,
        "name": store.get('USERNAME'),
        "holding": [],
        "discarded": [],
        "showing": []
      });
      this.saveState();
    },
    saveState() {
      FirebaseClient.colSessions()
          .doc(this.routeSessionId)
          .update(this.getState())
          .then(() => {
            console.log('state saved');
          });
    },
    setSessionListener() {
      FirebaseClient.colSessions()
          .doc(this.routeSessionId)
          .onSnapshot((doc) => {
            this.updateState(doc.data());
          });
    },
    updateState(state) {
      console.log('state change');

      let oldState = {};
      let newState = {};

      Object.keys(state).forEach((key) => {
        let value = state[key];
        oldState[key] = this[key];
        newState[key] = value;
        this.$set(this, key, value);
        this.session = true;
      });

      this.currentPlayerId = this.playerId;

      // if (this.visuallyImpaired && oldState['logs'] && oldState['logs'].length > 0) {
      //   let newLength = newState.logs.length - oldState['logs'].length;
      //   let newLogs = newState['logs'].slice(0, newLength);
      //   TextToSpeech.speak(newLogs.join(', '));
      //   console.log('newLogs', newLogs, newLogs.join(', '));
      // }
    },

    showPlayerControlView() {
      this.controlView = 'PLAYER';
    },
    showDealerControlView() {
      this.controlView = 'DEALER';
    },
    getState() {
      let data = {};
      STATE_KEYS.forEach((key) => {
        data[key] = this[key];
      });

      console.log(data);
      return data;
    },
    log(action, data) {
      let sentence;
      switch (action) {
        case LOG_ACTIONS.DEALER_DEAL_CARDS_TO_PLAYER:
          sentence = `Dealer deals ${data.count} cards to ${data.player.name}`;
          break;
        case LOG_ACTIONS.DEALER_SHUFFLE:
          sentence = `Dealer shuffles`;
          break;
        case LOG_ACTIONS.DEALER_BUILD_DECK:
          sentence = `Dealer builds deck`;
          break;
        case LOG_ACTIONS.SHOW_CARDS:
          let showCards = data.cards.map((card) => {
            return card.name;
          });
          sentence = `${data.player.name} shows ${showCards.join(', ')}`;
          break;
        case LOG_ACTIONS.DISCARD_CARDS:
          sentence = `${data.player.name} discards ${data.cards.length} cards`;
          break;
        case LOG_ACTIONS.UNDISCARD_CARDS:
          sentence = `${data.player.name} undiscards ${data.cards.length} cards`;
          break;
        case LOG_ACTIONS.UNSHOW_CARDS:
          let hideCards = data.cards.map((card) => {
            return card.name;
          });
          sentence = `${data.player.name} hides ${hideCards.join(', ')}`;
          break;
      }

      console.log(action, data, sentence);

      this.logs.unshift(sentence);
    },

    labelsFor(player) {
      let labels = [];
      if (this.dealerPlayerId !== undefined && this.dealerPlayerId === player.id) {
        labels.push({
          text: 'D',
          color: 'green'
        })
      }

      return labels;
    },
    collectShowing() {
      let collected = this.players.flatMap((player) => {
        let showing = player.showing.slice(0);
        player.showing = [];
        return showing;
      });

      this.collectedCards = this.collectedCards.concat(collected);
      this.saveState();
    },
    collectDiscarded() {
      let collected = this.players.flatMap((player) => {
        let discarded = player.discarded.slice(0);
        player.discarded = [];
        return discarded;
      });

      this.collectedCards = this.collectedCards.concat(collected);
      this.saveState();
    },
    putDiscardedBackToDeck() {
      this.deck = this.deck.concat(this.collectedCards);
      this.collectedCards = [];
      this.saveState();
    },

    showCardsForPlayer(playerIndex, holdingIndexes) {
      let player = this.players[playerIndex];
      let holding = player.holding.slice(0);
      let showing = player.showing.slice(0);

      this.log(LOG_ACTIONS.SHOW_CARDS, {
        player,
        cards: holding.filter((card, index) => {
          return holdingIndexes.indexOf(index) > -1;
        })
      });

      holdingIndexes.forEach((index) => {
        let card = holding[index];
        showing.push(card);
      });

      holding = holding.filter((card, index) => {
        return holdingIndexes.indexOf(index) === -1;
      });

      this.players[playerIndex].holding = holding;
      this.players[playerIndex].showing = showing;

      this.saveState();
    },

    unshowCardsForPlayer(playerIndex, showingIndexes) {
      let player = this.players[playerIndex];
      let holding = player.holding.slice(0);
      let showing = player.showing.slice(0);

      this.log(LOG_ACTIONS.UNSHOW_CARDS, {
        player,
        cards: showing.filter((card, index) => {
          return showingIndexes.indexOf(index) > -1;
        })
      });

      showingIndexes.forEach((index) => {
        let card = showing[index];
        holding.push(card);
      });

      showing = showing.filter((card, index) => {
        return showingIndexes.indexOf(index) === -1;
      });

      this.players[playerIndex].holding = holding;
      this.players[playerIndex].showing = showing;

      this.saveState();
    },

    discardCardsForPlayer(playerIndex, discardIndexes) {
      let player = this.players[playerIndex];
      let holding = player.holding.slice(0);
      let discarded = player.discarded.slice(0);

      this.log(LOG_ACTIONS.DISCARD_CARDS, {
        player,
        cards: holding.filter((card, index) => {
          return discardIndexes.indexOf(index) > -1;
        })
      });

      discardIndexes.forEach((index) => {
        let card = holding[index];
        discarded.push(card);
      });

      holding = holding.filter((card, index) => {
        return discardIndexes.indexOf(index) === -1;
      });

      this.players[playerIndex].holding = holding;
      this.players[playerIndex].discarded = discarded;

      this.saveState();
    },

    undiscardCardsForPlayer(playerIndex, discardIndexes) {
      let player = this.players[playerIndex];
      let holding = player.holding.slice(0);
      let discarded = player.discarded.slice(0);

      this.log(LOG_ACTIONS.UNDISCARD_CARDS, {
        player,
        cards: discarded.filter((card, index) => {
          return discardIndexes.indexOf(index) > -1;
        })
      });

      discardIndexes.forEach((index) => {
        let card = discarded[index];
        holding.push(card);
      });

      discarded = discarded.filter((card, index) => {
        return discardIndexes.indexOf(index) === -1;
      });

      this.players[playerIndex].holding = holding;
      this.players[playerIndex].discarded = discarded;

      this.saveState();
    },

    showCardForPlayer(playerIndex, holdingIndex) {
      let player = this.players[playerIndex];
      let holding = player.holding.slice(0);
      let showing = player.showing.slice(0);
      let card = holding.splice(holdingIndex, 1).shift();
      showing.push(card);
      this.players[playerIndex].holding = holding;
      this.players[playerIndex].showing = showing;
    },

    unshowCardForPlayer(playerIndex, holdingIndex) {
      let player = this.players[playerIndex];
      let holding = player.holding.slice(0);
      let showing = player.showing.slice(0);
      let card = showing.splice(holdingIndex, 1).shift();
      holding.push(card);
      this.players[playerIndex].holding = holding;
      this.players[playerIndex].showing = showing;
    },
    cardRankToNumber(rank) {
      // ace jack queen king
      switch (rank) {
        case 'jack':
          return 11;
        case 'queen':
          return 12;
        case 'king':
          return 13;
        case 'ace':
          return 14;
        default:
          return parseInt(rank);
      }
    },
    sortCards(cards) {
      let _this = this;
      let sortedCards = [];
      let cardGroups = {};

      cards.forEach((card) => {
        if (!cardGroups[card.suit]) {
          cardGroups[card.suit] = [];
        }

        cardGroups[card.suit].push(card);
      });

      Object.keys(cardGroups).forEach((suit) => {
        let cards = cardGroups[suit];
        cardGroups[suit] = cards.sort((a, b) => {
          let aValue = _this.cardRankToNumber(a.rank);
          let bValue = _this.cardRankToNumber(b.rank);

          if (aValue > bValue) {
            return -1;
          }

          if (bValue > aValue) {
            return 1;
          }

          return 0;
        })
      });

      ['spades', 'hearts', 'clubs', 'diamonds'].forEach((suit) => {
        if (cardGroups[suit]) {
          sortedCards = sortedCards.concat(cardGroups[suit]);
        }
      });

      return sortedCards;
    },
    dealCardsToPlayer(playerIndex, count) {
      let _this = this;
      let players;
      if (playerIndex === 'all') {
        players = this.players;
      } else {
        players = [
          this.players[playerIndex]
        ]
      }

      players.forEach((player) => {
        this.log(LOG_ACTIONS.DEALER_DEAL_CARDS_TO_PLAYER, {
          player,
          count
        });

        let cards = this.deck.splice(0, count);
        player.holding = player.holding.concat(cards);

        player.holding = _this.sortCards(player.holding);
      });

      this.saveState();
    },
    createPlayers() {
      this.players = [];
      for (let i = 0; i < this.playerCount; i++) {
        this.players.push({
          id: i,
          name: `Player ${i + 1}`,
          holding: [],
          discarded: [],
          showing: []
        })
      }
    },
    buildDeck() {
      this.log(LOG_ACTIONS.DEALER_BUILD_DECK);
      this.deck = this.possibleCards.slice(0);
    },
    shuffleDeck() {
      this.log(LOG_ACTIONS.DEALER_SHUFFLE);
      this.deck = shuffleSeed.shuffle(this.deck, `${this.seed}_${(new Date()).toISOString()}`);
      this.saveState();
    },
    reset() {
      this.collectedCards = [];
      this.buildDeck();
      this.shuffleDeck();
      this.players.forEach((player, index) => {
        player['discarded'] = [];
        player['holding'] = [];
        player['showing'] = [];
      });

      this.saveState();
    }
  },
  created() {
    this.setSessionListener();
  },
  components: {
    DealerControls,
    OpponentPerspective,
    PlayerControls,
    CardImage,
  },
}
</script>