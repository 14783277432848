<template>
  <div>
    <v-card>
      <v-card-text class="pa-2">
        <div class="holding">
          <div class="d-flex">
            <div class="flex-grow-1 d-flex align-center">
              <div class="labels pr-1" v-if="labels.length > 0">
                <template v-for="label in labels">
                  <v-chip x-small v-bind:color="label.color">{{ label.text }}</v-chip>
                </template>
              </div>
              <div class="player-name">{{ player.name }}</div>
            </div>

            <div class="flex-grow-1 d-flex justify-end" v-if="player.holding.length > 0">
              <div class="d-flex holding">
                <card-image class="card-image holding pa-1" suit="holding" name="back" rank="back"/>
              </div>
              <div class="d-flex justify-center align-center">
                <div class="holding-count">{{ player.holding.length }}</div>
              </div>
            </div>

            <div class="d-flex discarded">
              <div class="d-flex justify-end">
                <card-image class="card-image holding pa-1" suit="discarded" name="back" rank="back"/>
              </div>
              <div class="d-flex justify-center align-center">
                <div class="discarded-count">{{ player.discarded.length }}</div>
              </div>
            </div>

          </div>
        </div>

        <div class="showing">
          <template v-for="card in player.showing">
            <card-image class="card-image showing pa-1" v-bind:suit="card.suit" v-bind:name="card.name" v-bind:rank="card.rank"/>
          </template>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import CardImage from '@/components/card-image'

export default {
  name: 'opponent-perspective',
  props: {
    player: {
      required: true
    },
    labels: {
      default() {
        return [];
      }
    }
  },
  data: () => ({}),
  computed: {
    imagePath() {
      return `${this.rank}_of_${this.suit}.svg`;
    }
  },
  components: {
    CardImage
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.holding {

}

.player-name {
  font-weight: bold;
}

.card-image {
  &.holding {
    max-width: 30px;
  }

  &.showing {
    max-width: 60px;
  }
}

.holding-count {
  font-weight: bold;
}

.discarded-count {
  font-weight: bold;
}
</style>