<template>
  <div style="height: 100%;" class="d-flex flex-column">
    <div class="flex-grow-1 text-right">
      <small>{{ playerId }}</small>
    </div>
    <v-form>
      <v-text-field hide-details v-model="username" outlined label="Username"/>
      <template v-if="joinSessionId">
        <v-btn block v-bind:disabled="isFormSubmitDisabled" v-on:click="joinSession">Join Session</v-btn>
        <v-btn block v-bind:disabled="isFormSubmitDisabled" v-on:click="joinSessionAssist">Join Session - Assist</v-btn>
      </template>
      <template v-else>
        <v-btn block v-bind:disabled="isFormSubmitDisabled" v-on:click="createSession">Create Session</v-btn>
      </template>
    </v-form>
  </div>
</template>

<script>
import FirebaseClient from "@/clients/firebaseClient";
import session from '@/views/session.json'
import {DeviceUUID} from 'device-uuid'
import store from 'store'

export default {
  name: 'Home',
  data() {
    return {
      username: undefined,
      isSubmitting: false,
    }
  },
  computed: {
    playerId() {
      return store.get('PLAYER_ID');
    },
    joinSessionId() {
      return this.$route.query['joinSessionId'];
    },
    isFormSubmitDisabled() {
      if (!this.username) {
        return true;
      }

      if (this.isSubmitting) {
        return true;
      }

      return false;
    }
  },
  methods: {
    setPlayerId() {
      store.set('PLAYER_ID', `${new DeviceUUID().get()}_${(new Date()).getTime()}`);
    },
    storeUsername() {
      store.set('USERNAME', this.username);
    },
    joinSession() {
      this.storeUsername();
      this.navigateToSession(this.joinSessionId);
    },
    joinSessionAssist() {
      this.storeUsername();
      this.navigateToSession(this.joinSessionId, true);
    },
    createSession() {
      this.storeUsername();
      session.dealerPlayerId = this.playerId;
      session.players.push({
        "id": this.playerId,
        "name": store.get('USERNAME'),
        "holding": [],
        "discarded": [],
        "showing": []
      });

      FirebaseClient.colSessions()
          .add(session)
          .then((doc) => {
            this.navigateToSession(doc.id);
          });
    },
    navigateToSession(sessionId, visuallyImpaired) {
      this.$router.push({
        name: 'session-view',
        params: {
          sessionId: sessionId
        },
        query: {
          visuallyImpaired
        }
      })
    }
  },
  created() {
    this.username = store.get('USERNAME');
    if(!this.playerId) {
      this.setPlayerId();
    }
  },
  components: {},
}
</script>