<template>
  <div>
    <template v-if="visuallyImpaired">
      <div class="">
        <div style="height: 90px;">
          <template v-for="(card, holdingIndex) in player.discarded">
            <div class="d-inline-block" v-on:click="toggleFocusDiscarded(holdingIndex)">
              <card-image class="card-image px-1"
                          v-bind:class="{selected: selectedDiscarded.indexOf(holdingIndex) > -1}"
                          :selected="selectedDiscarded.indexOf(holdingIndex) > -1"
                          :suit="card.suit"
                          :name="card.name"
                          :rank="card.rank"/>
            </div>
          </template>
        </div>
        <div>
          <v-btn height="50" v-bind:disabled="selectedDiscarded.length === 0" v-on:click="undiscardSelectedDiscarded"
                 block>
            Undiscard
          </v-btn>
        </div>
      </div>

      <div class="py-2">
        <div style="height: 90px;">
          <template v-for="(card, showingIndex) in player.showing">
            <div class="d-inline-block" v-on:click="toggleFocusShowing(showingIndex)">
              <card-image class="card-image pa-1"
                          v-bind:class="{selected: selectedShowing.indexOf(showingIndex) > -1}"
                          :selected="selectedShowing.indexOf(showingIndex) > -1"
                          :suit="card.suit"
                          :name="card.name"
                          :rank="card.rank"/>
            </div>
          </template>
        </div>
        <div>
          <v-btn height="50" v-bind:disabled="selectedShowing.length === 0" v-on:click="hideSelectedShowing" block>
            Hide
          </v-btn>
        </div>
      </div>

      <div class="py-2">
        <div style="height: 90px;">
          <template v-for="(card, holdingIndex) in player.holding">
            <div class="d-inline-block" v-on:click="toggleFocusHolding(holdingIndex)">
              <card-image class="card-image px-1"
                          v-bind:class="{selected: selectedHolding.indexOf(holdingIndex) > -1}"
                          :selected="selectedHolding.indexOf(holdingIndex) > -1"
                          :suit="card.suit"
                          :name="card.name"
                          :rank="card.rank"/>
            </div>
          </template>
        </div>
        <div>
          <v-btn height="50px" color="green" class="mb-1 d-inline-block"
                 v-bind:disabled="selectedHolding.length === 0"
                 v-on:click="showSelectedHolding" block>Show
          </v-btn>
          <v-btn height="50px" color="red" class="d-inline-block" v-bind:disabled="selectedHolding.length === 0"
                 v-on:click="discardSelectedHolding" block>
            Discard
          </v-btn>
        </div>
      </div>

<!--      <div class="d-flex py-2">-->
<!--        <div class="flex-grow-1">-->
<!--          <v-btn height="100px" block v-on:click="sayShowing">Showing</v-btn>-->
<!--        </div>-->
<!--        <div class="flex-grow-1">-->
<!--          <v-btn height="100px" block v-bind:disabled="!(player.showing.length > 0 && selectShowingIndex > 0)"-->
<!--                 v-on:click="selectShowing(selectShowingIndex - 1)">Left-->
<!--          </v-btn>-->
<!--        </div>-->
<!--        <div class="flex-grow-1">-->
<!--          <v-btn height="100px" block-->
<!--                 v-bind:disabled="!(player.showing.length > 0 && selectShowingIndex < player.showing.length - 1)"-->
<!--                 v-on:click="selectShowing(selectShowingIndex + 1)">Right-->
<!--          </v-btn>-->
<!--        </div>-->
<!--        <div class="flex-grow-1">-->
<!--          <v-btn height="100px" v-bind:disabled="selectedShowing.length === 0" v-on:click="hideSelectedShowing" block>-->
<!--            Hide-->
<!--          </v-btn>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="d-flex py-2">-->
<!--        <div class="flex-grow-1">-->
<!--          <v-btn height="100px" block v-on:click="sayHolding">Holding</v-btn>-->
<!--        </div>-->
<!--        <div class="flex-grow-1">-->
<!--          <v-btn height="100px" block v-bind:disabled="!(player.holding.length > 0 && selectHoldingIndex > 0)"-->
<!--                 v-on:click="selectHolding(selectHoldingIndex - 1)">Left-->
<!--          </v-btn>-->
<!--        </div>-->
<!--        <div class="flex-grow-1">-->
<!--          <v-btn height="100px" block-->
<!--                 v-bind:disabled="!(player.holding.length > 0 && selectHoldingIndex < player.holding.length - 1)"-->
<!--                 v-on:click="selectHolding(selectHoldingIndex + 1)">Right-->
<!--          </v-btn>-->
<!--        </div>-->
<!--        <div class="flex-grow-1">-->
<!--          <v-btn height="100px" color="green" class="mb-1" v-bind:disabled="selectedHolding.length === 0"-->
<!--                 v-on:click="showSelectedHolding" block>Show-->
<!--          </v-btn>-->
<!--        </div>-->
<!--        <div class="flex-grow-1">-->
<!--          <v-btn height="100px" color="red" v-bind:disabled="selectedHolding.length === 0"-->
<!--                 v-on:click="discardSelectedHolding" block>-->
<!--            Discard-->
<!--          </v-btn>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="d-flex py-2">-->
<!--        <div class="flex-grow-1">-->
<!--          <v-btn height="100px" block v-on:click="sayDiscarded">Discarded</v-btn>-->
<!--        </div>-->
<!--        <div class="flex-grow-1">-->
<!--          <v-btn height="100px" block v-bind:disabled="!(player.discarded.length > 0 && selectDiscardedIndex > 0)"-->
<!--                 v-on:click="selectDiscarded(selectDiscardedIndex - 1)">Left-->
<!--          </v-btn>-->
<!--        </div>-->
<!--        <div class="flex-grow-1">-->
<!--          <v-btn height="100px" block-->
<!--                 v-bind:disabled="!(player.discarded.length > 0 && selectDiscardedIndex < player.discarded.length - 1)"-->
<!--                 v-on:click="selectDiscarded(selectDiscardedIndex + 1)">Right-->
<!--          </v-btn>-->
<!--        </div>-->
<!--        <div class="flex-grow-1">-->
<!--          <v-btn height="100px" color="red" v-bind:disabled="selectedDiscarded.length === 0"-->
<!--                 v-on:click="undiscardSelectedDiscarded" block>-->
<!--            Undiscard-->
<!--          </v-btn>-->
<!--        </div>-->
<!--      </div>-->
    </template>
    <template v-else>
      <div class="d-flex showing pa-1 elevation-2" v-if="player.showing.length > 0">
        <div class="flex-grow-1">
          <template v-for="(card, showingIndex) in player.showing">
            <div class="d-inline-block" v-on:click="toggleFocusShowing(showingIndex)">
              <card-image class="card-image pa-1"
                          v-bind:class="{selected: selectedShowing.indexOf(showingIndex) > -1}"
                          :suit="card.suit"
                          :name="card.name"
                          :rank="card.rank"/>
            </div>
          </template>
        </div>
        <div class="d-flex flex-column actions pl-1">
          <v-btn height="100px" v-bind:disabled="selectedShowing.length === 0" v-on:click="hideSelectedShowing" block>
            Hide
          </v-btn>
        </div>
      </div>

      <div class="d-flex holding pa-1 elevation-2">
        <div class="flex-grow-1">
          <template v-for="(card, holdingIndex) in player.holding">
            <div class="d-inline-block" v-on:click="toggleFocusHolding(holdingIndex)">
              <card-image class="card-image px-1"
                          v-bind:class="{selected: selectedHolding.indexOf(holdingIndex) > -1}"
                          :suit="card.suit"
                          :name="card.name"
                          :rank="card.rank"/>
            </div>
          </template>
        </div>
        <div class="d-flex flex-column actions pl-1">
          <v-btn height="100px" color="green" class="mb-1" v-bind:disabled="selectedHolding.length === 0"
                 v-on:click="showSelectedHolding" block>Show
          </v-btn>
          <v-btn height="100px" color="red" v-bind:disabled="selectedHolding.length === 0"
                 v-on:click="discardSelectedHolding" block>
            Discard
          </v-btn>
        </div>
      </div>

      <div class="d-flex discarded pa-1 elevation-2" v-if="player.discarded.length > 0">
        <div class="flex-grow-1">
          <template v-for="(card, holdingIndex) in player.discarded">
            <div class="d-inline-block" v-on:click="toggleFocusDiscarded(holdingIndex)">
              <card-image class="card-image px-1"
                          v-bind:class="{selected: selectedDiscarded.indexOf(holdingIndex) > -1}"
                          :suit="card.suit"
                          :name="card.name"
                          :rank="card.rank"/>
            </div>
          </template>
        </div>
        <div class="d-flex flex-column actions pl-1">
          <v-btn height="100px" v-bind:disabled="selectedDiscarded.length === 0" v-on:click="undiscardSelectedDiscarded"
                 block>
            Undiscard
          </v-btn>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import CardImage from "@/components/card-image";
import TextToSpeech from "@/services/textToSpeech";

export default {
  name: 'player-controls',
  props: {
    visuallyImpaired: undefined,
    player: {
      required: true
    }
  },
  data: () => ({
    selectShowingIndex: -1,
    selectHoldingIndex: -1,
    selectDiscardedIndex: -1,

    selectedHolding: [],
    selectedDiscarded: [],
    selectedShowing: []
  }),
  methods: {
    say(message) {
      if (!this.visuallyImpaired) {
        return;
      }

      // console.log('message', message);

      TextToSpeech.speak(message);
    },
    resetIndexes() {
      this.selectShowingIndex = -1;
      this.selectHoldingIndex = -1;
      this.selectDiscardedIndex = -1;
    },
    selectDiscarded(index) {
      this.selectedDiscarded = [
        index
      ];

      this.selectDiscardedIndex = index;

      this.say(`Selected discarded card ${this.sentenceSelectedDiscardedCards}`);
    },
    selectHolding(index) {
      this.selectedHolding = [
        index
      ];

      this.selectHoldingIndex = index;

      this.say(`Selected holding card ${this.sentenceSelectedHoldingCards}`);
    },
    selectShowing(index) {
      this.selectedShowing = [
        index
      ];

      this.selectShowingIndex = index;

      this.say(`Selected showing card ${this.sentenceSelectedShowingCards}`);
    },
    sayShowing() {
      let sentences = [
        `You are showing ${this.sentenceShowingCards}`,
      ]

      this.say(sentences.join(', '));
    },

    sayDiscarded() {
      let sentences = [
        `You discarded ${this.sentenceDiscardedCards}`,
      ]

      this.say(sentences.join(', '));
    },
    sayHolding() {
      let sentences = [
        `You are holding ${this.sentenceHoldingCards}`,
      ]

      this.say(sentences.join(', '));
    },

    hideSelectedShowing() {
      this.say(`Hiding ${this.sentenceSelectedShowingCards}`);
      this.unshowCards(this.selectedShowing);
      this.selectedShowing = [];
      this.resetIndexes();
    },
    showSelectedHolding() {
      this.say(`Showing ${this.sentenceSelectedHoldingCards}`);
      this.showCards(this.selectedHolding);
      this.selectedHolding = [];
      this.resetIndexes();
    },
    discardSelectedHolding() {
      this.say(`Discarding ${this.sentenceSelectedHoldingCards}`);

      this.discardCards(this.selectedHolding);
      this.selectedHolding = [];
      this.resetIndexes();
    },
    undiscardSelectedDiscarded() {
      this.say(`Undiscarding ${this.sentenceSelectedDiscardedCards}`);

      this.undiscardCards(this.selectedDiscarded);

      this.selectedDiscarded = [];
    },
    toggleFocusHolding(index) {
      if (this.selectedHolding.indexOf(index) > -1) {
        this.selectedHolding = this.selectedHolding.filter((holdingIndex) => {
          return holdingIndex !== index;
        });
      } else {
        this.selectedHolding.push(index);
      }
    },
    toggleFocusShowing(index) {
      if (this.selectedShowing.indexOf(index) > -1) {
        this.selectedShowing = this.selectedShowing.filter((showingIndex) => {
          return showingIndex !== index;
        });
      } else {
        this.selectedShowing.push(index);
      }
    },
    toggleFocusDiscarded(index) {
      if (this.selectedDiscarded.indexOf(index) > -1) {
        this.selectedDiscarded = this.selectedDiscarded.filter((discardedIndex) => {
          return discardedIndex !== index;
        });
      } else {
        this.selectedDiscarded.push(index);
      }
    },
    discardCards(holdingIndex) {
      console.log('discard-cards', holdingIndex);
      this.$emit('discard-cards', holdingIndex);
    },
    undiscardCards(holdingIndex) {
      console.log('undiscard-cards', holdingIndex);
      this.$emit('undiscard-cards', holdingIndex);
    },
    showCards(holdingIndexes) {
      console.log('show', holdingIndexes);
      this.$emit('show-cards', holdingIndexes);
    },
    unshowCards(showingIndexes) {
      console.log('show', showingIndexes);
      this.$emit('unshow-cards', showingIndexes);
    }
  },
  computed: {
    sentenceSelectedDiscardedCards() {
      let holding = this.selectedDiscarded.map((index) => {
        let card = this.player.discarded[index];
        return card.name;
      });

      if (holding.length === 0) {
        return 'no cards';
      }

      return holding.join(', ');
    },
    sentenceSelectedHoldingCards() {
      let holding = this.selectedHolding.map((index) => {
        let card = this.player.holding[index];
        return card.name;
      });

      console.log('holding', holding);

      if (holding.length === 0) {
        return 'no cards';
      }

      return holding.join(', ');
    },
    sentenceSelectedShowingCards() {
      let showing = this.selectedShowing.map((index) => {
        let card = this.player.showing[index];
        return card.name;
      });

      if (showing.length === 0) {
        return 'no cards';
      }

      return showing.join(', ');
    },
    sentenceShowingCards() {
      let showing = this.player.showing.map((card) => {
        return card.name;
      });

      if (showing.length === 0) {
        return 'no cards';
      }

      return showing.join(', ');
    },
    sentenceHoldingCards() {
      let holding = this.player.holding.map((card) => {
        return card.name;
      });


      if (holding.length === 0) {
        return 'no cards';
      }

      return holding.join(', ');
    },
    sentenceDiscardedCards() {
      let holding = this.player.discarded.map((card) => {
        return card.name;
      });


      if (holding.length === 0) {
        return 'no cards';
      }

      return holding.join(', ');
    }
  },
  components: {
    CardImage
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.card-image {
  width: 100%;
  max-width: 60px;

  &.selected {
    border: 2px solid yellow;
  }

  //&.holding {
  //  max-width: 30px;
  //}
  //
  //&.showing {
  //  max-width: 80px;
  //}
}

.actions {
  width: 100px;
  min-width: 100px;
}

.holding {
  background-color: #333;
}

.discarded {
  background-color: red;
}

.showing {
  background-color: green;
}

.player-name {
  font-weight: bold;
}

.holding-count {
  font-weight: bold;
}

.discarded-count {
  font-weight: bold;
}
</style>